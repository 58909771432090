import { createAction, props } from "@ngrx/store";
import { ProjectEventModel } from "../project-event.model";
import { EventModel } from '../event.model';
import { IEventNotificationModel } from '../event-notifications';

export enum ActionTypes {
  SUCCESS_CREATE_UPDATE_EVENT = 'SUCCESS_CREATE_UPDATE_EVENT',
  PENDING_CREATE_UPDATE_EVENT = 'PENDING_CREATE_UPDATE_EVENT',
  UPDATE_EVENT_FIELD = 'UPDATE_EVENT_FIELD',
  UPDATE_EVENT_FIELDS = 'UPDATE_EVENT_FIELDS',
  CHECK_CURRENT_EVENT_FOR_PENDING_CHANGES = 'CHECK_CURRENT_EVENT_FOR_PENDING_CHANGES',
  DISPACTHED_CREATE_UPDATE_EVENT = 'DISPACTHED_CREATE_UPDATE_EVENT',
  REVERT_EVENT_EDITOR_CHANGES = 'REVERT_EVENT_EDITOR_CHANGES',

  // project events
  REMOVE_PROJECT_EVENT = 'REMOVE_PROJECT_EVENT',
  UPDATE_PROJECT_EVENT_IN_LIST = 'UPDATE_PROJECT_EVENT_IN_LIST',
  RESET_CURRENT_PROJECT_EVENT = 'RESET_CURRENT_PROJECT_EVENT',
  DELETE_PROJECT_EVENT = 'DELETE_PROJECT_EVENT',
  LOAD_PROJECT_EVENT_LIST_BY_PROJECT_ID = 'LOAD_PROJECT_EVENT_LIST_BY_PROJECT_ID',
  UPDATE_PROJECT_EVENT_LIST = 'UPDATE_PROJECT_EVENT_LIST',
  CREATE_PROJECT_EVENT = 'CREATE_PROJECT_EVENT',
  POPULATE_CURRENT_PROJECT_EVENT_SNAPSHOT = 'POPULATE_CURRENT_PROJECT_EVENT_SNAPSHOT',
  POPULATE_CURRENT_PROJECT_EVENT = 'POPULATE_CURRENT_PROJECT_EVENT',
  UPDATE_PROJECT_EVENT = 'UPDATE_PROJECT_EVENT',
  UPDATE_EDITOR = 'UPDATE_EDITOR',
  CHANGE_CURRETNT_PROJECT_EVENT = 'CHANGE_CURRETNT_PROJECT_EVENT',
  RESET_PROJECT_EVENT_LIST = 'RESET_PROJECT_EVENT_LIST',
  GET_EVENTS_COMMENTS_COUNTER = 'GET_EVENTS_COMMENTS_COUNTER',
  POPULATE_EVENTS_COMMENTS_COUNTER = 'POPULATE_EVENTS_COMMENTS_COUNTER',

  //event notifications
  LOAD_EVENT_NOTIFICATIONS_LIST_BY_EVENT_ID = 'LOAD_EVENT_NOTIFICATIONS_LIST_BY_EVENT_ID',
  UPDATE_EVENT_NOTIFICATIONS_LIST = 'UPDATE_EVENT_NOTIFICATIONS_LIST',
  RESET_EVENT_NOTIFICATIONS_LIST = 'RESET_EVENT_NOTIFICATIONS_LIST',
}

export const successCreateUpdateEvent = createAction(ActionTypes.SUCCESS_CREATE_UPDATE_EVENT, props<{
  success: boolean
}>());
export const pendingCreateUpdateEvent = createAction(ActionTypes.PENDING_CREATE_UPDATE_EVENT, props<{
  pending: boolean
}>());
export const updateEventField = createAction(ActionTypes.UPDATE_EVENT_FIELD, props<{ field: string, value: any }>());
export const updateEventFields = createAction(ActionTypes.UPDATE_EVENT_FIELDS, props<{ event: Partial<EventModel> }>());
export const dispatchedCreateUpdateEvent = createAction(ActionTypes.DISPACTHED_CREATE_UPDATE_EVENT, props<{
  dispatched: boolean
}>());
export const checkCurrentEventForPendingChanges = createAction(ActionTypes.CHECK_CURRENT_EVENT_FOR_PENDING_CHANGES, props<{
  changes: boolean
}>());

// project events
export const removeProjectEvent = createAction(ActionTypes.REMOVE_PROJECT_EVENT, props<{ projectEventId: number }>());
export const populateCurrentProjectEventSnapshot = createAction(ActionTypes.POPULATE_CURRENT_PROJECT_EVENT_SNAPSHOT, props<{
  projectEvent: ProjectEventModel
}>());
export const populateCurrentProjectEvent = createAction(ActionTypes.POPULATE_CURRENT_PROJECT_EVENT, props<{
  projectEvent: ProjectEventModel
}>());
export const createProjectEvent = createAction(ActionTypes.CREATE_PROJECT_EVENT, props<{
  projectEvent: ProjectEventModel
}>());
export const updateProjectEvent = createAction(ActionTypes.UPDATE_PROJECT_EVENT, props<{
  projectEvent: ProjectEventModel
}>());
export const deleteProjectEvent = createAction(ActionTypes.DELETE_PROJECT_EVENT, props<{ id: number }>());
export const loadProjectEventListByProjectId = createAction(ActionTypes.LOAD_PROJECT_EVENT_LIST_BY_PROJECT_ID, props<{
  id: number
}>());
export const updateProjectEventList = createAction(ActionTypes.UPDATE_PROJECT_EVENT_LIST, props<{
  projectEventList: ProjectEventModel[]
}>());
export const resetCurrentProjectEvent = createAction(ActionTypes.RESET_CURRENT_PROJECT_EVENT);
export const updateProjectEventInList = createAction(ActionTypes.UPDATE_PROJECT_EVENT_IN_LIST, props<{
  projectEvent: ProjectEventModel
}>());
export const updateEditor = createAction(ActionTypes.UPDATE_EDITOR);
export const revertEventEditorChanges = createAction(ActionTypes.REVERT_EVENT_EDITOR_CHANGES);
export const changeCurrentProjectEvent = createAction(ActionTypes.CHANGE_CURRETNT_PROJECT_EVENT, props<{
  eventId: number
}>());
export const resetProjectEventList = createAction(ActionTypes.RESET_PROJECT_EVENT_LIST);
export const getProjectEventsCommentsCounter = createAction(ActionTypes.GET_EVENTS_COMMENTS_COUNTER);
export const populateProjectEventsCommentsCounter = createAction(ActionTypes.POPULATE_EVENTS_COMMENTS_COUNTER, props<{
  counters: any[]
}>());

export const loadEventNotificationsListByEventId = createAction(ActionTypes.LOAD_EVENT_NOTIFICATIONS_LIST_BY_EVENT_ID, props<{
  id: number
}>());

export const updateEventNotificationsList = createAction(ActionTypes.UPDATE_EVENT_NOTIFICATIONS_LIST, props<{
  eventNotifications: IEventNotificationModel[]
}>());

export const resetEventNotificationsList = createAction(ActionTypes.RESET_EVENT_NOTIFICATIONS_LIST);
