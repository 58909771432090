<phar-entity-comments
  [users]="usersList()"
  [currentUser]="user()"
  [comments]="comments"
  [isViewOnly]="false"
  [showCloseButton]="true"
  (commentDeleted)="handleDeleteComment($event)"
  (commentResolved)="handleResolveComment($event)"
  (commentUpdated)="handleEditComment($event)"
  (commentCreated)="handleCreateComment($event)"
  (requestClose)="closeDialog()"
/>
