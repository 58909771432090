import { Component, inject, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IEventComment } from './event-comment.interface';
import { SharedModule } from '../../shared/shared.module';
import { EntityCommentsBaseComponent } from '../../shared/entity-comments/entity-comment-base/entity-component-base.component';
import { IEntityComment } from '../../shared/models/entity-comments.interface';
import { ProjectService } from '../../project/project.service';
import { take } from 'rxjs/operators';
import { errorPopup, messagePopup } from '../../store/actions/ui.actions';

@Component({
  selector: 'phar-event-comment-dialog',
  templateUrl: 'event-comment.dialog.component.html',
  styleUrl: 'event-comment.dialog.component.scss',
  standalone: true,
  imports: [
    SharedModule
  ]
})

export class EventCommentDialogComponent extends EntityCommentsBaseComponent implements OnInit {
  declare comments: IEventComment[];
  projectService: ProjectService = inject(ProjectService);

  constructor(@Inject(MAT_DIALOG_DATA) public data: {
    projectEventId: number
  }, public dialogRef: MatDialogRef<EventCommentDialogComponent>,) {
    super();
  }

  ngOnInit() {
    this.loadComments();
  }

  private loadComments(): void {
    const { projectEventId } = this.data;
    if (!projectEventId) {
      return;
    }

    this.projectService.getProjectEventComments(projectEventId).pipe(
      take(1)
    ).subscribe({
      next: (comments) => {
        this.comments = comments;
      }, error: () => {
        this.store.dispatch(errorPopup({ error: 'Failed to load comments' }));
      }
    })
  }

  handleDeleteComment($event: IEntityComment) {
    this.projectService.deleteProjectEventComment($event.id).pipe(
      take(1)
    ).subscribe({
      next: () => {
        this.store.dispatch(messagePopup({ message: 'Comment delete' }));
        this.closeDialog(true);
      },
      error: () => {
        this.store.dispatch(errorPopup({ error: 'Failed to delete comment' }));
      }
    });

  }

  handleResolveComment($event: IEntityComment) {
    this.projectService.resolveProjectEventComment($event.id).pipe(
      take(1)
    ).subscribe({
      next: () => {
        this.store.dispatch(messagePopup({ message: 'Comment resolved' }));
        this.closeDialog();
      },
      error: () => {
        this.store.dispatch(errorPopup({ error: 'Failed to resolve comment' }));
      }
    });

  }

  handleEditComment($event: IEntityComment) {
    this.projectService.updateProjectEventComment($event as IEventComment).pipe(
      take(1)
    ).subscribe({
      next: () => {
        this.store.dispatch(messagePopup({ message: 'Comment updated' }));
        this.closeDialog();
      },
      error: () => {
        this.store.dispatch(errorPopup({ error: 'Failed to update comment' }));
      }
    });

  }

  handleCreateComment($event: IEntityComment) {
    const payload: IEventComment = {
      ...$event,
      projectEventId: this.data.projectEventId
    }
    this.projectService.createProjectEventComment(payload).pipe(
      take(1)
    ).subscribe({
      next: () => {
        this.store.dispatch(messagePopup({ message: 'Comment created' }));
        this.closeDialog(true);
      },
      error: () => {
        this.store.dispatch(errorPopup({ error: 'Failed to create comment' }));
      }
    });
  }

  closeDialog(shouldRefreshCounters = false): void {
    this.dialogRef.close(shouldRefreshCounters);
  }
}
