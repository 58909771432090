import { Component, Inject, inject, OnInit, signal } from '@angular/core';
import { EntityCommentsBaseComponent } from '../../shared/entity-comments/entity-comment-base/entity-component-base.component';
import { AssignService } from '../assign.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { IAssignComment } from './assign-comment.interface';
import { SharedModule } from '../../shared/shared.module';
import { IEntityComment } from '../../shared/models/entity-comments.interface';
import { errorPopup, messagePopup } from '../../store/actions/ui.actions';

@Component({
  templateUrl: 'assign-comments-dialog.component.html',
  standalone: true,
  imports: [
    SharedModule
  ]
})

export class AssignCommentsDialogComponent extends EntityCommentsBaseComponent implements OnInit {
  declare comments: IAssignComment[];
  assignService: AssignService = inject(AssignService);
  isLoading = signal(false);

  constructor(@Inject(MAT_DIALOG_DATA) public data: { assignmentId: number },
              public dialogRef: MatDialogRef<AssignCommentsDialogComponent>
  ) {
    super();
  }

  ngOnInit() {
    this.isLoading.set(true);
    this.loadComments();
  }

  handleDeleteComment($event: IEntityComment) {
    this.assignService.deleteComment($event.id).pipe(
      take(1)
    ).subscribe({
      next: () => {
        this.store.dispatch(messagePopup({ message: 'Comment deleted' }));
        this.closeDialog(true);
      }, error: () => {
        this.store.dispatch(errorPopup({ error: 'Unable to delete a comment' }));
      }
    })
  }

  handleResolveComment($event: IEntityComment) {
    this.assignService.resolveComment($event.id).pipe(
      take(1)
    ).subscribe({
      next: () => {
        this.store.dispatch(messagePopup({ message: 'Comment resolved' }));
        this.closeDialog();
      },
      error: () => {
        this.store.dispatch(errorPopup({ error: 'Unable to resolve a comment' }));
      }
    })
  }

  handleEditComment($event: IEntityComment) {
    this.assignService.updateComment($event as IAssignComment).pipe(
      take(1)
    ).subscribe({
      next: () => {
        this.store.dispatch(messagePopup({ message: 'Comment updated' }));
        this.closeDialog();
      }, error: () => {
        this.store.dispatch(errorPopup({ error: 'Unable to update the comment' }));
      }
    })
  }

  handleCreateComment($event: IEntityComment) {
    const data: IAssignComment = {
      assignmentId: this.data.assignmentId,
      ...$event
    }

    this.assignService.createComment(data).pipe(
      take(1)
    ).subscribe({
      next: () => {
        this.store.dispatch(messagePopup({ message: 'Comment created' }));
        this.closeDialog(true);
      },
      error: () => {
        this.store.dispatch(errorPopup({ error: 'Unable to create a comment' }));
      }
    })
  }

  closeDialog(refreshCounters = false) {
    this.dialogRef.close(refreshCounters);
  }

  loadComments(): void {
    this.assignService.getCommentsByAssignId(this.data.assignmentId).pipe(
      take(1)
    ).subscribe({
      next: (data: IAssignComment[]) => {
        this.isLoading.set(false);
        this.comments = data;
      },
      error: () => {
        this.isLoading.set(false);

      }
    })
  }
}
